<template>
  <div>
    <v-card>
      <v-card-title class="text-h6">{{
        $t("labels.change_delivery")
      }}</v-card-title>
      <v-card-text>{{ $t("labels.select_delivery_company") }}</v-card-text>

      <v-card-text>
        <v-autocomplete
          v-model="idDeliveryContract"
          :items="deliveryContracts"
          :label="$t('labels.select_delivery_company')"
          dense
          outlined
          clearable
          hide-details
        ></v-autocomplete>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancel">
          {{ $t("labels.cancel") }}
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="confirm"
          v-if="idDeliveryContract"
        >
          {{ $t("labels.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { TVC_DELIVERY_CONTRACT } from "@/libs/const";

export default {
  name: "UpdateDeliveryCompany",
  components: {},
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    idDeliveryContract: null,
    deliveryContracts: [],
  }),
  mounted() {
    this.getDeliveryContracts();
  },
  methods: {
    async getDeliveryContracts() {
      const url = this.order.id_pos
        ? "/pos/v1/order-online-get-delivery-for-change"
        : "/common/v1/get-delivery-for-change";
      const { data } = await httpClient.post(url, {
        id_warehouse: this.order.id_warehouse,
        id_pos: this.order.id_pos,
        id_customer: this.order.id_customer,
        id_order: this.order.id,
      });
      data.unshift({
        id: TVC_DELIVERY_CONTRACT,
        name: this.$t("labels.tvc"),
      });
      this.deliveryContracts = [...data].map((e) => ({
        value: e.id,
        text: e.name,
      }));
    },
    cancel() {
      this.$emit("cancel");
    },
    async confirm() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      const url = this.order.id_pos
        ? "/pos/v1/order-online-change-delivery-company"
        : "/goods-issue-detail/v1/change-delivery-company";
      try {
        await httpClient.post(url, {
          id_delivery_contract: this.idDeliveryContract,
          id_orders: [this.order.id],
        });
        this.isLoading = false;
        this.$root.$emit("playSuccessAudio");
        this.cancel();
        this.$emit("refreshData");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
  },
};
</script>

<style scoped></style>
